













































import { computed, PropType, defineComponent, toRef } from '@nuxtjs/composition-api';
import { extractImage } from '@vsf-enterprise/amplience';
import { CellarOverviewCardProps, ImageHolderInterface } from '@amplience/types';

export default defineComponent({
  name: 'CellarCardsOverview',
  props: {
    imageHolder: {
      type: [Object, null] as PropType<ImageHolderInterface | null>,
      default: null,
    },
    bbx: {
      type: [Object, null] as PropType<CellarOverviewCardProps | null>,
      default: null,
    },
    selling: {
      type: [Object, null] as PropType<CellarOverviewCardProps | null>,
      default: null,
    },
    bidding: {
      type: [Object, null] as PropType<CellarOverviewCardProps | null>,
      default: null,
    },
  },
  setup(props) {
    const bbxCard = toRef(props, 'bbx');
    const sellingCard = toRef(props, 'selling');
    const biddingCard = toRef(props, 'bidding');

    const getImageSrc = (): string | null => {
      if (props.imageHolder?.poi?.image) {
        const image = extractImage(props.imageHolder.poi.image);

        return image.url;
      }

      return null;
    };

    const cards = computed<CellarOverviewCardProps[]>(() => [
      bbxCard.value,
      sellingCard.value,
      biddingCard.value,
    ].filter(Boolean));

    return {
      cards,
      getImageSrc,
    };
  },
});
