




















































import { storeToRefs } from 'pinia';
import { defineComponent, computed, useFetch } from '@nuxtjs/composition-api';
import { SfLink, SfButton } from '@storefront-ui/vue';
import { useCustomerStore } from '~/modules/customer/stores/customer';
import { useContent } from '@amplience/composables/useContent';
import useUser from '~/modules/customer/composables/useUser';
import CellarCardsOverview from '@amplience/components/content-type/CellarCardsOverview.vue';

export default defineComponent({
  name: 'BBXDashboard',
  components: {
    CellarCardsOverview,
    SfLink,
    SfButton,
  },
  setup() {
    const customerStore = useCustomerStore();
    const { search, data } = useContent();
    const { user } = useUser();

    useFetch(async () => {
      await search('cellar-cards-overview', 'url');
    });

    const { sapUniqueID, isBidder, isSeller } = storeToRefs(customerStore);

    const registerAsSellerButtonLabel = computed(() => isSeller.value ? 'Amend Selling Details' : 'Register');
    const registerAsBidderButtonLabel = computed(() => isBidder.value ? 'Amend Buying Details' : 'Register');

    const registerAsSellerMessage = computed(() => (
      isSeller.value ? 'You are a BBX Registered Seller' : 'Register to sell on BBX'
    ));

    const registerAsBidderMessage = computed(() => (
      isBidder.value ? 'You are a BBX Registered Bidder' : 'Register to bid on BBX'
    ));

    const redirectRegisterSeller = () => window.location.assign('/my-account/bbx/seller-registration');
    const redirectRegisterBidder = () => window.location.assign('/my-account/bbx/bidder-registration');

    return {
      registerAsSellerButtonLabel,
      registerAsBidderButtonLabel,
      registerAsSellerMessage,
      registerAsBidderMessage,
      sapUniqueID,
      data,
      user,
      redirectRegisterSeller,
      redirectRegisterBidder,
    }
  },
  head() {
    return {
      title: 'My BBX Dashboard',
    };
  },
});
